const initialState = {
  user: {},
  authProcess: {
    page: 0,
    splashScreenUsed: false,
    userEmail: '',
    userEmailValidation: {
      isEmpty: true,
      isValid: false,
      exists: false,
      checkedIfExists: false,
    },
    showUserEmailError: false,
    newPassword: '',
    repeatedPassword: '',
    showNewPasswordError: false,
    showRepeatedPasswordError: false,
    resetPasswordValidation: {
      isNewPasswordEmpty: true,
      isRepeatedPasswordEmpty: true,
      areEquals: false,
      errorFromServer: '',
    },
    password: '',
    passwordValidation: {
      isEmpty: true,
      exists: false,
      checkedIfExists: false,
    },
    showPasswordError: false,
    isLogged: false,
    token: '',
    passwordResetRequests: {
      creationDate: '',
      requestsMade: 0,
      id: '',
    },
    availablePasswordResetRequestsNumber: 0,
    userNotEnabled: false,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SPLASH_SCREEN_USED_STATUS':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          splashScreenUsed: action.splashScreenUsed,
        },
      }
    case 'SET_AUTH_PROCESS_PAGE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          page: action.page,
        },
      }

    case 'UPDATE_USEREMAIL':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          userEmail: action.userEmail,
          userEmailValidation: {
            ...state.authProcess.userEmailValidation,
            isEmpty: action.isEmpty,
            isValid: action.isValid,
            checkedIfExists: false,
          },
        },
      }

    case 'SET_USER_EMAIL_ERROR_VISIBLE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          showUserEmailError: action.showUserEmailError,
        },
      }

    case 'UPDATE_USER_EMAIL_STATUS':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          page: action.page,
          showUserEmailError: action.showUserEmailError,
          userEmailValidation: {
            ...state.authProcess.userEmailValidation,
            checkedIfExists: true,
            exists: action.exists,
          },
        },
      }

    case 'UPDATE_PASSWORD':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          password: action.password,
          passwordValidation: {
            ...state.authProcess.passwordValidation,
            isEmpty: action.isEmpty,
            checkedIfExists: false,
          },
        },
      }

    case 'UPDATE_NEW_PASSWORDS':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          newPassword: action.newPassword,
          repeatedPassword: action.repeatedPassword,
          showNewPasswordError: false,
          showRepeatedPasswordError: false,
          resetPasswordValidation: {
            ...state.authProcess.resetPasswordValidation,
            isNewPasswordEmpty: action.isNewPasswordEmpty,
            isRepeatedPasswordEmpty: action.isRepeatedPasswordEmpty,
            areEquals: action.areEquals,
            errorFromServer: '',
          },
        },
      }

    case 'SET_PASSWORD_ERROR_VISIBLE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          showPasswordError: action.showPasswordError,
        },
      }

    case 'SET_NEW_PASSWORD_ERROR_VISIBLE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          showNewPasswordError: action.showNewPasswordError,
        },
      }

    case 'SET_REPEATED_PASSWORD_ERROR_VISIBLE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          showRepeatedPasswordError: action.showRepeatedPasswordError,
        },
      }

    case 'UPDATE_LOGIN_STATUS':
      return {
        ...state,
        user: action.user,
        authProcess: {
          ...state.authProcess,
          isLogged: action.isLogged,
          token: action.token,
          showPasswordError: action.showPasswordError,
          passwordValidation: {
            ...state.authProcess.passwordValidation,
            checkedIfExists: true,
            exists: action.exists,
          },
        },
      }

    case 'UPDATE_USER_NOT_ENABLED':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          userNotEnabled: action.userNotEnabled,
        },
      }

    case 'UPDATE_PASS_RESET_REQUESTS_AVAILABILITY_AND_PAGE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          page: action.page,
          availablePasswordResetRequestsNumber: action.availablePasswordResetRequestsNumber,
        },
      }

    case 'UPDATE_PASS_RESET_REQUESTS_STATUS':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          page: action.page,
          passwordResetRequests: action.passwordResetRequests,
          availablePasswordResetRequestsNumber: action.availablePasswordResetRequestsNumber,
        },
      }

    case 'UPDATE_RESET_PASSWORD_SERVER_RESPONSE':
      return {
        ...state,
        authProcess: {
          ...state.authProcess,
          resetPasswordValidation: {
            ...state.authProcess.resetPasswordValidation,
            errorFromServer: action.errorFromServer,
          },
        },
      }

    default:
      return state
  }
}

export default reducer
