import { createStore as reduxCreateStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import appVersion from './reducers/app_version'
import authentication from './reducers/authentication'
import comments from './reducers/comments'
import comparisonBoard from './reducers/comparison_board'
import device from './reducers/device'
import downloaded from './reducers/downloaded'
import environment from './reducers/environment'
import home from './reducers/home'
import interRanking from './reducers/inter_ranking'
import media from './reducers/media'
import msgbox from './reducers/msgbox'
import offlineScoringsList from './reducers/offline'
import scopes from './reducers/scopes'
import scoring from './reducers/scoring'
import scoringTree from './reducers/scoring_tree'
import search from './reducers/search'
import status from './reducers/status'
import sync from './reducers/sync'
import syncV2 from './reducers/sync_v2'
import templates from './reducers/templates'
import texts from './reducers/texts'
import publishingServices from './reducers/publishing_services'
import clipboard from './reducers/clipboard'
import report from './reducers/report'

const rootReducer = combineReducers({
  appVersion,
  authentication,
  comments,
  comparisonBoard,
  device,
  downloaded,
  environment,
  home,
  interRanking,
  media,
  msgbox,
  offlineScoringsList,
  scopes,
  scoring,
  scoringTree,
  search,
  status,
  sync,
  syncV2,
  templates,
  texts,
  publishingServices,
  clipboard,
  report,
})

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
        trace: true,
      })
    : compose

const createStore = () => reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default createStore
