const initialState = {
  productsFromServer: [],
  scoringId: '',
  nodeDefId: '',
  templateId: '',
  activeScore: false,
  carouselIndex: false,
  zoomedImageIndex: false,
  visible: false,
  filteredScore: false,
  pin: false,
  focusedMedia: [],
  focusedComments: [],
  pinnedMedia: [],
  pinnedComments: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_INTER_RANKING':
      return {
        ...state,
        visible: action.visible,
        activeScore: action.activeScore,
        nodeDefId: action.nodeDefId,
        templateId: action.templateId,
        scoringId: action.scoringId,
      }
    case 'UPDATE_INTER_RANKING_ACTIVE_SCORE':
      return {
        ...state,
        activeScore: action.activeScore,
      }

    case 'UPDATE_INTER_RANKING_PRODUCTS_FROM_SERVER':
      return {
        ...state,
        productsFromServer: action.productsFromServer,
        carouselIndex: action.carouselIndex,
      }

    case 'UPDATE_INTER_RANKING_ZOOMED_IMAGE':
      return {
        ...state,
        productsFromServer: action.productsFromServer,
        zoomedImageIndex: action.zoomedImageIndex,
      }

    case 'UPDATE_INTER_RANKING_PRODUCTS_LIST':
      return {
        ...state,
        productsList: action.productsList,
      }

    case 'UPDATE_INTER_RANKING_CAROUSEL_INDEX':
      return {
        ...state,
        carouselIndex: action.carouselIndex,
      }

    case 'UPDATE_INTER_RANKING_FILTERED_SCORE':
      return {
        ...state,
        filteredScore: action.filteredScore,
      }

    case 'UPDATE_INTER_RANKING_PIN':
      return {
        ...state,
        pin: action.pin,
      }

    case 'UPDATE_FOCUSED_MEDIA_AND_COMMENTS':
      return {
        ...state,
        focusedMedia: action.media,
        focusedComments: action.comments,
      }

    case 'UPDATE_PINNED_MEDIA_AND_COMMENTS':
      return {
        ...state,
        pinnedMedia: action.media,
        pinnedComments: action.comments,
      }

    case 'EMPTY_INTER_RANKING':
      return {
        ...state,
        productsFromServer: [],
        scoringId: '',
        nodeDefId: '',
        templateId: '',
        activeScore: false,
        carouselIndex: false,
        zoomedImageIndex: false,
        visible: false,
        filteredScore: false,
        pin: false,
        focusedMedia: [],
        focusedComments: [],
        pinnedMedia: [],
        pinnedComments: [],
      }

    default:
      return state
  }
}

export default reducer
