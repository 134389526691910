const initialState = {
  list: [],
}

const reducer = (state = initialState, action) => {
  let list = []
  switch (action.type) {
    case 'UPDATE_MEDIA':
      return {
        ...state,
        list: action.media,
      }
    case 'UPDATE_IMAGE':
      return {
        ...state,
        list: [action.media, ...state.list],
      }
    case 'EDIT_IMAGE':
      list = [...state.list]
      list = list.map(item => {
        return item.id === action.media.id ? action.media : item
      })
      return {
        ...state,
        list,
      }
    default:
      return state
  }
}

export default reducer
